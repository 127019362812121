app-chat-standalone {
  * {
    box-sizing: border-box;
    font-family: Inter, sans-serif;
  }
  
  html {
    text-size-adjust: 100%;
    font-size: 10px;
    height: 100%;
    width: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    overflow-x: hidden;
    background-color: #fff;
    color: #141414;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }
  
  .responsive {
    height: 100%;
    width: 100%;
  }
}