@mixin rotate($rotation-angle) {
    -webkit-transform: rotate(#{$rotation-angle}deg);
    -moz-transform: rotate(#{$rotation-angle}deg);
    -ms-transform: rotate(#{$rotation-angle}deg);
    -o-transform: rotate(#{$rotation-angle}deg);
    transform: rotate(#{$rotation-angle}deg);
}

@mixin max-lines($count) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $count;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 0 3px 0;
}