/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@use 'globals' as g;
@use 'mixins' as m;

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'assets/fontello/css/fontello.css';
@import 'app/assistant.scss';
@import 'app/bootstrap-4-partial';

body {

  // It is quite hard to FORCE ionic to use another font than Roboto 8-)
  // Ionic theming simply does not work like documented (or there is some other problem with the ionic theming setup).
  // Even using a SASS variable for the definitions below does not work, it must be spelled out three times.
  ion-app {
    font-family: 'Assistant', 'Roboto', sans-serif ! important;

    &.md,
    &.ios,
    &.wp {
      font-family: 'Assistant', 'Roboto', sans-serif ! important;

      &>* {
        font-family: 'Assistant', 'Roboto', sans-serif ! important;
      }
    }

    &.platform-ios12 {
      ion-content {
        pointer-events: auto;
      }
    }
  }

  ion-menu ion-content {
    --padding-top: 60px;
  }

  ion-router-outlet ion-content {
    --padding-top: 80px;
    --padding-start: var(--cdz-contentpadding);
    --padding-end: var(--cdz-contentpadding);
    --background: var(--cdz-background-color);
  }

  .is-login .ion-page>ion-content {
    background-color: var(--cdz-menu-color) ! important;
  }

  .not-is-login .ion-page>ion-content {
    background-color: var(--cdz-darecolor) ! important;
  }

  /*
  * {
    contain: none!important;
  }
  */
  .bar-button-default {
    background-color: var(--cdz-darkcolor);
    color: #ffffff;

    &.bar-button-menutoggle {
      color: var(--cdz-primary-color);
    }
  }

  .toolbar-background,
  .toolbar-title {
    background-color: var(--cdz-secondary-color);
    color: #ffffff;
  }

  .header {
    &::after {
      display: none;
    }
  }

  .anonymous-form {
    max-width: 500px;
  }

  .ion-page {
    &>.content {
      &>.fixed-content {
        /*
        background-color: #DDDDDD;
        */
        background-color: transparent;
      }

      &>.scroll-content {
        /*
        margin: 15px !important;
        background-color: #ffffff;
        border: 1px solid #CCCCCC;
        */
        background-color: transparent;
      }
    }

    &>.header {
      .toolbar {
        position: relative;
        border-bottom: 1px solid var(--cdz-darecolor);
      }
    }
  }

  .item {
    background-color: transparent;
  }

  .highlight {
    color: var(--cdz-primary-color) ! important;
  }

  .toolbar-title {
    text-align: left;
    padding: 0;
    color: var(--cdz-primary-color);
  }

  .ion-page .toolbar-title {
    text-align: right;
  }

  .toolbar ion-title {
    padding: 0 1px 1px;
    position: static;
  }

  .item>.icon,
  .button:not(.icon-button)>.icon {
    margin-right: 10px;
  }

  .badge-button {
    position: relative;

    .button-badge {
      position: absolute;
      bottom: 0px;
      right: -5px;
      font-size: 12px;
      border-radius: 50%;
      padding: 2px;
      margin: 0;
      min-width: 16px;
      height: 16px;
    }
  }

  // general modal custom design
  .modal-default ion-content {
    position: relative;

    .large-container {
      padding: 0 var(--cdz-contentpadding);
      position: relative;

      // previously and next button
      .view-prev,
      .view-next {
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        font-size: 35px;
        cursor: pointer;
        color: var(--cdz-primary-color);
        z-index: 99999;
      }

      .view-prev {
        left: 0;
        margin-left: -10px;
      }

      .view-next {
        right: 0;
        margin-right: -10px;
      }
    }

    .button-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  // videochat modal
  .modal-default:has(> page-bewerbungsservice-jitsi-modal.ion-page) {
    --width: 1000px;
  }

  // cometchat would otherwise block all inputs
  #cometchat__widget {
    height: 0 !important;
    width: 0 !important;
  }

  span.show-lines-1 {
    @include m.max-lines(1);
  }

  span.show-lines-2 {
    @include m.max-lines(1);
  }

  span.show-lines-3 {
    @include m.max-lines(3);
  }

  span.show-lines-4 {
    @include m.max-lines(4);
  }

  span.show-lines-5 {
    @include m.max-lines(5);
  }

  // swiper cards
  swiper-container {
    --swiper-pagination-color: var(--cdz-primary-color);
    --swiper-pagination-bullet-horizontal-gap: 6px;

    swiper-slide {
      padding-bottom: 35px;
      height: auto;
    }
  }

  ion-button.button.button-disabled {
    --background: var(--cdz-button-disabled-color);
  }

  ion-toast {
    &.toast-success {
      --background: var(--ion-color-success);
    }

    &.toast-error {
      --background: var(--ion-color-danger);
    }
  }

  ion-searchbar {
    margin-bottom: 10px !important;

    &.md {
      --background: transparent !important;
      --border-radius: 50px !important;
      --box-shadow: none !important;
      --ion-color-step-850: var(--cdz-primary-color);
      --ion-color-step-600: var(--cdz-primary-color);
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      padding-inline-start: 0 !important;
      padding-inline-end: 0 !important;

      .searchbar-input {
        border: 1px solid var(--cdz-primary-color) !important;
      }
    }

    &.ios {}

  }

  ion-select {
    color: var(--cdz-primary-color);
    margin-bottom: 10px !important;

    &.md {
      --background: transparent !important;
      --ion-color-step-500: var(--cdz-primary-color);
      --placeholder-color: var(--cdz-primary-color);
      --highlight-color-focused: var(--cdz-primary-color);
      --highlight-color-valid: var(--cdz-primary-color);
      --highlight-color-invalid: var(--cdz-primary-color);
      border-radius: 50px;
      border: 1px solid var(--cdz-primary-color);
      padding: 0 15px;
    }

    &.ios {}
  }

  .cdz-section {
    &:not(.last) {
      border-bottom: 1px solid var(--cdz-midcolor);
      padding-bottom: 10px;
      margin-bottom: 20px;
    }

    i {
      color: var(--cdz-bullcolor);
    }
  }
}

@media (max-width: g.$cdz-mobilemax) {

  .alert-radio-group.sc-ion-alert-md,
  .alert-checkbox-group.sc-ion-alert-md {
    // this overrides an ionic style that caused issues where a alert window for multi-selects looked blurry
    max-height: none;
  }

  //TODO
  .not-mobile {
    display: none !important;
  }

  .ion-page {
    ion-content {
      --padding-start: var(--cdz-contentpadding-mobile);
      --padding-end: var(--cdz-contentpadding-mobile);
    }

    .popover-viewport ion-content {
      --padding-top: 0;
      --padding-bottom: 0;
    }

    h1 {
      font-size: 20px;
      line-height: 20px;
      font-weight: bold;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    h2 {
      font-size: 18px;
      line-height: 18px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  .modal-default ion-content .large-container {
    padding: 0 var(--cdz-contentpadding-mobile) !important;
  }

  .meta-group-outer {
    .meta-group-list-outer {
      &:last-child {
        margin-bottom: 10px !important;
        padding-bottom: 10px !important;
      }
    }
  }

  meta-field {
    padding-bottom: 5px !important;
  }
}

@media (min-width: g.$cdz-tabletmin) and (max-width: g.$cdz-tabletmax) {

  //TODO
  .not-tablet {
    display: none !important;
  }
}

@media (max-width: g.$cdz-tabletmax) {
  body {
    .ion-page .title.logo-title {
      background-position: calc(100% + 50px) center;
    }
  }
}

@media (min-width: g.$cdz-desktopmin) {

  //TODO
  .not-desktop {
    display: none !important;
  }

  body {
    .ion-page>.header .toolbar {
      padding-left: 29px;
      padding-right: 29px;
    }

    .ion-page .toolbar-title {
      text-align: left;
    }

    .top-menu {
      padding-left: 4px;
    }
  }
}

//Modal fixtures, override ionics modal layout
body {
  .app-root {
    .modal-wrapper {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      bottom: 0;
      right: 0;
      border-radius: 0;
      pointer-events: none;

      .ion-page>ion-content {
        background-color: var(--cdz-darkcolor) !important;
      }
    }
  }
}

// things used by more than one page

// forms

.form-item {
  margin-bottom: 10px;

  label {
    font-size: 80%;
    display: block;
    font-weight: 500;
  }

  input {

    &[type=text],
    &[type=password],
    &[type=email] {
      //TODO ... continue the list, while excluding the following types: hidden, radio, checkbox, file, ...
      border: 1px solid var(--cdz-litecolor);
      border-radius: 4px;
      line-height: 36px;
      height: 36px;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;

      &.ng-invalid:not(.ng-pristine) {
        border-left: 5px solid var(--cdz-primary-color);
      }
    }
  }
}

input[type=file] {
  display: none;
}

.icon-none:before {
  content: ' '; // icons specified without icon should still take space and not collapse ...
}

// buttons
// .button, .button:hover:not(.disable-hover) {
//   &.default-button, &.cancel-button {
//     box-shadow: none;
//     text-transform: none;
//     background-color: var(--cdz-orange);
//     padding: 0 3em;
//     margin: 0;
//     margin-top: 5px;
//     border-radius: 4px;
//     border-width: 0;
//     height: 2.8em;
//   }
//   &.cancel-button {
//     color: var(--cdz-orange);
//     border: 1px solid var(--cdz-orange);
//     background-color: transparent;
//   }
//   &.icon-button {
//     box-shadow: none;
//     text-transform: none;
//     background-color: transparent;
//     padding: 0;
//     margin: 0;
//     border-width: 0;
//     color: var(--cdz-orange);
//     font-size: 30px;
//     min-height: 30px;
//   }
// }

// list filters
.list-filter {
  border-top: 1px solid var(--cdz-litecolor);
  border-bottom: 1px solid var(--cdz-litecolor);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;

  &>button {
    min-height: 36px;
    margin-right: 10px !important;
    flex: 1 2 auto;
  }

  &>select,
  &>input {
    border-radius: 4px;
    margin-right: 10px;
    border: 1px solid var(--cdz-midcolor);
    padding-left: 10px;
    padding-right: 10px;
    min-height: 36px;
    min-width: 0;
    flex: 1 5 auto;

    &.placeholder {
      color: var(--cdz-midcolor);

      option {
        color: var(--cdz-darkcolor);
      }
    }

    option:first-child {
      color: var(--cdz-midcolor);
    }
  }

  &>select {
    flex: 1 20 auto;
  }

  &>input {
    flex: 3 4 250px;
  }

  &>.icon-button,
  .filter-spacer>.icon-button {
    color: var(--cdz-midcolor);
    min-width: 40px;
    min-height: 36px;
    flex: 1 1 auto;
  }

  &>.filter-spacer {
    flex: 100 1 0;
    text-align: right;
    white-space: nowrap;
  }
}

.lined-list {
  border-top: 1px solid var(--cdz-litecolor);
  border-bottom: 1px solid var(--cdz-litecolor);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.lined-list-empty {
  border-top: 1px solid var(--cdz-litecolor);
  border-bottom: 1px solid var(--cdz-litecolor);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-top: 15px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 14px;
  color: var(--cdz-bullcolor);
}

@media (max-width: g.$cdz-mobilemax) {

  .list-filter,
  .button-foot {
    display: block;

    .button {
      display: block;
      width: 100%;

      &+.button {
        margin-top: 10px;
      }
    }
  }
}

// document list
.list-document {
  .item {
    padding: 5px 0;

    ion-label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      font-size: 18px;
      color: var(--cdz-primary-color);
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.2;

      a {
        color: var(--cdz-primary-color) !important;
      }
    }

    h2 {
      font-size: 14px;
      color: var(--cdz-darkcolor);
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 5px;
      line-height: 1.2;

      span {
        color: var(--cdz-midcolor);
      }
    }

    h3 {
      font-size: 14px;
      color: var(--cdz-darkcolor);
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 1.2;
    }

    .button.icon-button {
      font-size: 16px;
    }
  }
}

// layout fixes re Ionic nervy
body {
  .list-ios>.item-block:first-child {
    border-top-width: 0;
  }

  .list-ios>.item-block:last-child,
  .list-ios>.item-wrapper:last-child .item-block {
    border-bottom-width: 0;
  }

  /*
  .button-ios:not(.btn-modal, .link-modal) {
    height: unset;
  }
  */
}

// buttons on page bottom
.button-foot {
  border-top: 1px solid var(--cdz-litecolor);
  margin-top: 30px;
  padding-top: 20px;
}

// fix back button icon
.back-button-icon {
  &:before {
    font-family: inherit;
    color: var(--cdz-primary-color);
  }
}

// error toast
.toast-error {
  .toast-container {
    border: 5px solid var(--cdz-primary-color);
  }
}

//text formattings

.plain-html {}

.pre-line {
  white-space: pre-line;
}