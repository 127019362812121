// global styles that should only be applied to the mobile version
ion-app.cdz-mobile {
    .bold-text {
        font-weight: bold;
    }

    .full-width {
        width: 100%;
    }

    h1 {
        color: var(--cdz-primary-color);
        font-weight: normal;
        margin: 15px 0;

        &.page-header {
            margin: 0 0 15px 0;
        }
    }

    h2 {
        color: var(--cdz-primary-color);
        font-weight: bold;
        margin: 20px 0;
    }

    h3 {
        font-weight: bold;
        margin: 10px 0;
        font-size: 16px;
    }

    ol,
    ul {
        padding-inline-start: 20px;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    // remove background from ion lists
    ion-list {
        --ion-background-color: transparent;
        --padding-start: 0px;

        ion-item {
            --padding-start: 0px;
        }
    }
}