// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@use 'globals' as g;
@use 'mixins' as m;

/** Ionic CSS Variables **/
:root {
  /** custom variablen **/
  --cdz-globalheaderheight: 150px;
  --cdz-leftheaderheight: 140px;
  --cdz-globalbarheight: 56px;
  --cdz-wrapwidth: 1400px;
  --cdz-menuwidth-maximized: 320px;
  --cdz-menuwidth-collapsed: 60px;
  --cdz-menu-transition: ease-in-out 400ms;
  --cdz-contentpadding: 30px;
  --cdz-contentpadding-mobile: 10px;

  /** colors used for borders and other details **/
  --cdz-litecolor: #DDDDDD;
  --cdz-midcolor: #BBBBBB;
  --cdz-bullcolor: #444444;
  --cdz-darecolor: #303030;
  --cdz-darkcolor: #1A1A1A;

  /** button color for disabled buttons **/
  --cdz-button-disabled-color: #818181;

  /** primary highlight color - will be replaced by backend value **/
  --cdz-primary-color: #fb7900;
  --cdz-primary-color-contrast: #ffffff;
  --cdz-primary-color-light: var(--cdz-primary-color);
  --cdz-primary-color-lighter: var(--cdz-primary-color);
  --cdz-primary-color-dark: var(--cdz-primary-color);
  --cdz-primary-color-darker: var(--cdz-primary-color);
  // above are default values for browsers that do still not support hsl(...)
  @supports (background: hsl(from var(--dummy) h s l)) {
    --cdz-primary-color-light: hsl(from var(--cdz-primary-color) h s calc(l + 20));
    --cdz-primary-color-lighter: hsl(from var(--cdz-primary-color) h s calc(l + 30));
    --cdz-primary-color-dark: hsl(from var(--cdz-primary-color) h s calc(l - 20));
    --cdz-primary-color-darker: hsl(from var(--cdz-primary-color) h s calc(l - 30));
  }

  /** secondary highlight color - will be replaced by backend value **/
  --cdz-secondary-color: #1a1a1a;
  --cdz-secondary-color-contrast: #fb7900;
  /** menu background color - will be replaced by backend value **/
  --cdz-menu-color: #eeeeee;
  /** background color - will be replaced by backend value **/
  --cdz-background-color: #ffffff;

  /** overriding ionic variables **/
  --ion-toolbar-background: var(--cdz-secondary-color) !important;
  --ion-toolbar-color: var(--cdz-secondary-color-contrast) !important;
  --ion-background-color: var(--cdz-menu-color) !important;
  --ion-font-family: Assistant, Roboto, sans-serif !important;

  /** primary **/
  --ion-color-primary: var(--cdz-primary-color) !important;
  --ion-color-primary-rgb: var(--cdz-primary-color) !important;
  --ion-color-primary-contrast: var(--cdz-primary-color-contrast) !important;
  --ion-color-primary-contrast-rgb: var(--cdz-primary-color-contrast) !important;
  --ion-color-primary-shade: var(--cdz-tertiary-color) !important;
  --ion-color-primary-tint: var(--cdz-secondary-color) !important;

  /** secondary **/
  --ion-color-secondary: var(--cdz-secondary-color) !important;
  --ion-color-secondary-rgb: var(--cdz-secondary-color) !important;
  --ion-color-secondary-contrast: var(--cdz-secondary-color-contrast) !important;
  --ion-color-secondary-contrast-rgb: var(--cdz-secondary-color-contrast) !important;
  --ion-color-secondary-shade: var(--cdz-secondary-color) !important;
  --ion-color-secondary-tint: var(--cdz-secondary-color) !important;

  /** tertiary **/
  --ion-color-tertiary: #5260ff !important;
  --ion-color-tertiary-rgb: 82, 96, 255 !important;
  --ion-color-tertiary-contrast: #ffffff !important;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255 !important;
  --ion-color-tertiary-shade: #4854e0 !important;
  --ion-color-tertiary-tint: #6370ff !important;

  /** success **/
  --ion-color-success: #11c95b !important;
  --ion-color-success-rgb: 45, 211, 111 !important;
  --ion-color-success-contrast: #ffffff !important;
  --ion-color-success-contrast-rgb: 255, 255, 255 !important;
  --ion-color-success-shade: #28ba62 !important;
  --ion-color-success-tint: #42d77d !important;

  /** warning **/
  --ion-color-warning: #ffc409 !important;
  --ion-color-warning-rgb: 255, 196, 9 !important;
  --ion-color-warning-contrast: #000000 !important;
  --ion-color-warning-contrast-rgb: 0, 0, 0 !important;
  --ion-color-warning-shade: #e0ac08 !important;
  --ion-color-warning-tint: #ffca22 !important;

  /** danger **/
  --ion-color-danger: #e41a35 !important;
  --ion-color-danger-rgb: 235, 68, 90 !important;
  --ion-color-danger-contrast: #ffffff !important;
  --ion-color-danger-contrast-rgb: 255, 255, 255 !important;
  --ion-color-danger-shade: #cf3c4f !important;
  --ion-color-danger-tint: #ed576b !important;

  /** dark **/
  --ion-color-dark: #222428 !important;
  --ion-color-dark-rgb: 34, 36, 40 !important;
  --ion-color-dark-contrast: #ffffff !important;
  --ion-color-dark-contrast-rgb: 255, 255, 255 !important;
  --ion-color-dark-shade: #1e2023 !important;
  --ion-color-dark-tint: #383a3e !important;

  /** medium **/
  --ion-color-medium: #92949c !important;
  --ion-color-medium-rgb: 146, 148, 156 !important;
  --ion-color-medium-contrast: #ffffff !important;
  --ion-color-medium-contrast-rgb: 255, 255, 255 !important;
  --ion-color-medium-shade: #808289 !important;
  --ion-color-medium-tint: #9d9fa6 !important;

  /** light **/
  --ion-color-light: #f4f5f8 !important;
  --ion-color-light-rgb: 244, 245, 248 !important;
  --ion-color-light-contrast: #000000 !important;
  --ion-color-light-contrast-rgb: 0, 0, 0 !important;
  --ion-color-light-shade: #d7d8da !important;
  --ion-color-light-tint: #f5f6f9 !important;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   * nothing to do here for now
   */
}